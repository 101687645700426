@use "../../styles/mq" as *;

.logo {
  width: 100%;

  @include mq($from: tablet) {
    max-width: 660px;
    width: 70vw;
  }

  img {
    width: 100%;
  }
}
