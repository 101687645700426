@use "../../styles/mq" as *;

.nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  z-index: 1;

  @include mq($from: mobile) {
    align-items: inherit;
    gap: 12px;
  }

  &__item {
    font-size: 16px;
    text-decoration: none;
    color: rgb(199, 199, 199);
    transition: color 0.25s;

    @include mq($until: mobile) {
      border: 1px solid white;
      border-radius: 5px;
      padding: 8px 16px;
    }

    &:hover {
      color: white;
    }
  }
}
