h1,
h2,
h3 {
  margin: 0;
}

h1 {
  font-size: 64px;
}

h2 {
  font-size: 48px;
}
h3 {
  font-size: 32px;
}
